$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-text {
  a {
    text-decoration: underline !important;
  }
  sup, sub {
    font-size: 35%;
    position: relative;
  }
  sup {
    top: -0.5em;
  }
  .sw-blue {
    color: map-get($colors, sw-blue);
  }
  .sw-green {
    color: map-get($colors, sw-green);
  }
  .sw-orange {
    color: map-get($colors, sw-orange);
  }
  .sw-purple {
    color: map-get($colors, sw-purple);
  }
  .sw-sustainability-green {
    color: map-get($colors, sw-sustainability-green);
  }
  .sw-sustainability-yellow {
    color: map-get($colors, sw-sustainability-yellow);
  }
  .sw-teal {
    color: map-get($colors, sw-teal);
  }
  .sw-yellow {
    color: map-get($colors, sw-yellow);
  }
}
